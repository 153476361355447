a:link { text-decoration: none; }


a:visited { text-decoration: none; }


a:hover { text-decoration: none; }


a:active { text-decoration: none; }

.search-bar {
  display: flex;
  align-items: center;
  width: 38rem;
}
.App {
  text-align: center;
  width: 100%;
  height: 100%;
  background-color: hsl(0deg 0% 0% / 81%) !important;
  overflow-y: hidden;
}
.buttonPrimary{
  background-color: aquamarine;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}



/* Nav Bar Menu */
.burger-menu{
  margin-left: -55px;
  margin-top: 1px;
  display: none;
}
.burger-menu>i{
  font-size: 24px;
  cursor: pointer;
}

@media (max-width: 750px) {
  .burger-menu{
    display: block;
  }
}


/* Animaciones */

.fadeOut {
  opacity: 0;
  transition: width 0.5s 0.5s, height 0.5s 0.5s, opacity 0.5s;
  transition: all 0.5s;
  pointer-events: none;
  cursor: default;  
  right: -40% !important;
}

.fadeIn {
  opacity: 1;
  transition: width 0.5s, height 0.5s, opacity 0.5s 0.25s;
  transition: all ease 0.5s;
  right: 0 !important;
}

.slideDownFromTop{
  transition: all ease 0.5s;
  margin-top: 0 !important;
}

.slideUpFromTop{
  transition: all ease 0.5s;
  margin-top: -700px !important;
}

.slideLeftFromRight{
  transition: all ease 0.5s;
  margin-right: 0 !important;
}
.slideRightFromRight{
  transition: all ease 0.5s;
  margin-right: -700px !important;
}


