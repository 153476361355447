@import url("https://fonts.googleapis.com/css?family=Fira+Sans:400,500,600,700,800");

* {
  box-sizing: border-box;
}
body {
  background: hsl(0deg 0% 100%) !important;
  min-height: 100vh;
  display: flex;
  font-weight: 400;
  font-family: "Fira Sans", sans-serif;
  color:#ffffff;
}

h1,
h2,
h3,
h4,
h5,
h6,
label,
span {
  font-weight: 500;
  font-family: "Fira Sans", sans-serif;
}

body,
html,
.App,
#root,
.auth-wrapper {
  width: 100%;
  height: 100%;
}

.navbar-light {
  background-color: rgba(0, 0, 0, 0.807);
  color: rgb(0, 0, 0);
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
}

.auth-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
}

.auth-inner {
  width: 450px;
  margin: auto;
  background: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 40px 55px 45px 55px;
  border-radius: 15px;
  transition: all 0.3s;
}

.auth-wrapper .form-control:focus {
  border-color: #167bff;
  box-shadow: none;
}

.auth-wrapper h3 {
  text-align: center;
  margin: 0;
  line-height: 1;
  padding-bottom: 20px;
}

.custom-control-label {
  font-weight: 400;
}

.forgot-password,
.forgot-password a {
  text-align: right;
  font-size: 13px;
  padding-top: 10px;
  color: #7f7d7d;
  margin: 0;
}

.forgot-password a {
  color: #167bff;
}
.nav-link{
  color: #ffffff !important;
}
.navbar-brand{
  color: #ffffff !important;

}
.navbar-collapse{
  flex-direction: row-reverse;

}

.content {
  height: calc(100vh - 100px);
  width: calc(100vw - 45px);
  margin-top: 60px;
  margin-left: 45px;
  padding: 20px;
  background-color: white;
  overflow-y: auto;
}

@media (max-width: 750px) {
  .content{
    width: calc(100vw);
    margin-left: 0px;
  }
}

.input-error input, .input-error select, .input-error .ant-select-selector{
  border-color: red !important;
}

.input-error .ant-form-item-control-input-content {
  color: red;
}

.networks-container, .links-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  max-height: 250px;
  overflow-y: auto;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px;
}

.network-element, .link-element {
  display: flex;
  align-items: end;
  width: 100%;
  padding: 3px 0px 10px;
  border-bottom: 1px solid #ddd;
}

/* ===========================
  OVERRIDE CUSTOM ANT D STYLES
  ============================ */

.ant-modal-header {
  margin-bottom: 15px !important;
  padding-bottom: 10px;
  border-bottom: 1px solid #ddd;
}

.ant-form-item {
  margin-bottom: 8px;
}

.ant-row.ant-form-item-row{
  display: inline;
  flex-direction: column;
}
.ant-row.ant-form-item-row .ant-form-item-label {
  text-align: start;
}
.ant-row.ant-form-item-row .ant-form-item-label > label{
  height: 24px !important;
}

.ant-table-cell .ant-space-item, .icon-button{
  display: flex;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #ddd;
  padding: 8px;
}

.ant-input {
  width: 100%;
}

.ms-3 {
  margin-left: 15px;
}
.w-auto {
  width: auto !important;
}
.w-100 {
  width: 100% !important;
}

.image-preview {
  width: 102px;
  height: 102px;
  border-radius: 8px;
  float: left;
}

.image-preview img{
  width: 100%;
}

.file-preview {
  width: 100%;
  height: 200px;
  border-radius: 8px;
}

.file-preview img, .file-preview video{
  width: auto;
  height: 100%;
}

.ant-upload-list-item-thumbnail.ant-upload-list-item-file {
  display: flex;
  align-items: center;
  justify-content: center;
}

.buttonCentered {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.form-divider {
  padding: 7px 10px;
  border-radius: 5px;
  background-color: #ecfaf0;
}

.form-divider::before, .form-divider::after{
  border-block-start: 5px solid #263238 !important;
}
.radio-group-container {
  display: flex;
}
.radio-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.radio-container span{
  font-weight: 400;
}