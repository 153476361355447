.mainContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.7rem;
    padding-left: 1rem;
    color: rgb(29, 29, 29);
    cursor: pointer; 
    font-weight: 400;
    font-size: 14px;
    transition: all ease 0.25s;
}
.mainContainer>i{
    margin-right: 0.5rem;
}
.mainContainer:hover {
    background-color: aquamarine;
    
}

.mainContainer>* {
    margin: 0px;
}


/* Contenedor SubMenus GENERAL */
.containerSubMenus {
    display: flex;
    flex-direction: column;
    overflow: hidden;
}


/* Contenedor SubMenu DE CADA 1 */
.containerSubMenu {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: rgb(255, 255, 255);
    padding: 0.8rem 1rem;
    font-size: 14px;
    padding-left: 1.75rem;
    transition: all ease 0.15s;

}
.containerSubMenu:hover{
    color: #20c997;
}

.containerSubMenu>p {
    margin: 0px;
}

/* SI LA CLASE ESTA ACTIVADA ES DECIR CLICKEADA */
.menuActivated {
    background-color: #20c997;
}

