.componentContainer {
    display: flex;
    align-items: center;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3;
}

.blackBackground {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.382);
    top: 0;
    left: 0;
    position: fixed;
}

.mainContainer * {
    text-align: left;
    color: rgba(0, 0, 0, 0.688);
}

.mainContainer {
    width: 70%;
    height: fit-content;
    background-color: whitesmoke;
    z-index: 4;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    border-radius: 5px;
    color: black;
    margin-left: 3.5rem;
    margin-right: 1rem;
}

.mainContainer>header {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    padding: 1.25rem 2rem;
    background: rgb(2, 0, 36);
    background: linear-gradient(90deg, #00CC88 0%, #7fffd4 40%);
    color: black;
    border-radius: 5px 5px 0 0;
}

.mainContainer>header h5 {
    margin: 0px;
    text-align: left;
    color: black;
}

.mainContainer>header i {
    margin-left: auto;
    height: 20px;
    top: 0px;
    position: relative;
    font-size: 35px;
    font-weight: bolder;
    margin-top: -30px;
}

.containerContent {
    padding: 1rem;
    overflow-y: auto;
    max-height: 15rem;
}

/* Scrollbars */


.containerContent::-webkit-scrollbar-track {
    background-color: #d4d4d4;
    border-radius: 3px;
}

.containerContent::-webkit-scrollbar {
    width: 12px;
}

.containerContent::-webkit-scrollbar-thumb {
    border-radius: 3px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: #7fffd4;
}

.contianerVideo>video {
    width: 100%;
    height: 30rem;
    background-color: black;
}

/* Copyright titulos etc */
.titlesRates {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding-bottom: 0;
}

.titlesRates>div>p>a {
    text-decoration: none;
    list-style: none;
    color: #5697e6;
}

.mainContainer>article {
    padding: 1rem;
    padding-top: 0;
}

/* Botones */
.containerBtns {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 1rem;
    padding-top: 1rem;
}

.containerBtns>button {
    padding: 0.5rem 1.5rem;
    margin: 0 1rem;
    border: 0px;
    background-color: #7fffd4;
    border-radius: 10px;
    text-transform: capitalize;
    color: rgba(0, 0, 0, 0.781);
    font-weight: 600;
    transition: all 0.15s ease;
    border-radius: 5px;
}

.containerBtns button:hover {
    background-color: rgba(127, 255, 212, 0.766);
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

/* Fin Contenedor Izquierdo*/
.mainContainerRight h4,
.mainContainerRight h5 {
    margin: 0px;
}

.mainContainerRight {
    position: relative;
    margin-left: auto;
    width: 25rem;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.617);
}

.mainContainerRight>header {
    display: flex;
    padding: 1rem;
    align-items: center;
    background-color: #2d3131;
}

.mainContainerRight>header>i {
    font-size: 30px;
    margin-top: 0px;
    margin-right: 0.5rem;
}

.mainContainerRight>header>h5 {
    font-size: 25px;
    font-weight: 100;
}

.mainContainerRight>article * {
    text-align: justify;
}

.mainContainerRight>article {
    padding: 1rem 1.5rem;
    background-color: #212424;
    border-left: #00CC88 5px solid;
    border-top: 3px solid rgb(85, 85, 85);
}

.mainContainerRight>article>div {
    display: flex;
    align-items: center;
}

.mainContainerRight i {
    top: 0px;
    font-size: 35px;
    margin-top: -30px;
}

.mainContainerRight>article>div>h4 {
    font-weight: lighter;
}

.mainContainerRight>article>p {
    line-height: 35px;
}

.mainContainerRight>footer {
    margin: auto;
    border-top: 3px solid rgb(85, 85, 85);
    background-color: #212424;
    padding: 1rem;
    position: absolute;
    bottom: 0;
    width: 100%;
}

@media (max-width: 1000px) {
    .componentContainer {
        justify-content: center;
    }

    .mainContainer {
        width: 90%;
        margin: 0;
    }

    .mainContainerRight {
        display: none;
    }

}

@media (max-width: 700px) {
    .contianerVideo>video {
        width: 100%;
        height: 20rem;
        background-color: black;
    }

    .containerContent {
        max-height: 25rem;
    }

}